<template>
  <div ref="container">
    <div class="d-flex justify-space-between align-center mb-6">
      <slot name="label">
        <div class="qtm-label mt-4 text-uppercase text-dark-grey">
          <v-badge color="primary" dot :model-value="attention" offset-x="-4" offset-y="-2">
            Comments
          </v-badge>
        </div>
      </slot>
      <sort-menu-order
        v-if="comments.length"
        v-model="sortBy"
      />
    </div>

    <div
      class="d-flex flex-column"
      :class="{ 'flex-column-reverse': sortBy === 'oldest', 'separated': comments.length > 0 }"
    >
      <new-comment-box
        v-if="comments.length || showInput || !withEmpty"
        :autofocus="showInput"
        class="py-4"
        :content-id="props.contentId"
        :content-type="props.contentType"
        :mentionable-users="mentionableUsers"
        @add-comment="addComment"
        @preview="$emit('preview', $event)"
      />

      <div v-else class="qtm-body text-mid-grey text-center" :class="{ 'center-empty': centerEmpty }">
        Start discussion with your teammates
        <div>
          <qtm-btn prepend-icon="mdi-message-outline" tertiary @click="showInput = true">
            Add Comment
          </qtm-btn>
        </div>
      </div>

      <comment-row
        v-for="comment in sortedComments"
        :key="comment.id"
        class="py-4"
        :comment="comment"
        :download-only="downloadOnly"
        @preview="$emit('preview', $event)"
        @update:comment="onCommentUpdate"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import CommentRow from '@/components/comments/comment-row.vue'
import NewCommentBox from '@/components/comments/new-comment-box.vue'
import SortMenuOrder from '@/components/sort-menu-order.vue'

export interface Props {
  centerEmpty?: boolean
  contentId: number
  contentType: string
  downloadOnly?: boolean
  mentionableUsers: any[]
  withEmpty?: boolean
}

const props = defineProps<Props>()
const emit = defineEmits(['update:attention', 'preview'])

const attention = ref(false)
const comments = ref<any[]>([])
const sortBy = ref('newest')
const showInput = ref(false)

const sortedComments = computed(() => {
  return comments.value.sort((a, b) => new Date(b.date_created).getTime() - new Date(a.date_created).getTime())
})

const container = ref<any>(null)
const route = useRoute()
const { $api, $error } = useNuxtApp()

onMounted(async () => {
  if (route.query.comment) {
    container.value?.scrollIntoView({ behavior: 'smooth' })
  }

  try {
    comments.value = await $api.v1.comments.list(props.contentId, props.contentType)
  }
  catch (error) {
    $error.report(error)
  }
})

const addComment = (createdComment: any) => {
  comments.value.push(createdComment)
}

const onCommentUpdate = (comment: any) => {
  const updatedComments = [...comments.value]
  const index = updatedComments.findIndex((c) => c.id === comment.id)

  if (index !== -1) {
    updatedComments[index] = comment
  }

  comments.value = updatedComments
}

watch(comments, () => {
  attention.value = comments.value.some((comment) => comment.pending_read_receipts?.length)
  emit('update:attention', attention.value)
})
</script>

<style lang="scss" scoped>
.center-empty {
  margin-top: 50%;
}

$border: 1px solid rgb(var(--v-theme-light-grey));

.separated > *:not(:last-child) {
  border-bottom: $border;
}

.separated.flex-column-reverse > *:first-child {
  border-bottom: none;
}

.separated.flex-column-reverse > *:last-child {
  border-bottom: $border;
}
</style>

<style lang="scss">
.v-list-item:hover {
  color: rgb(var(--v-theme-interactive)) !important;
  background-color: rgb(var(--v-theme-interactive-lighten-1));
}
</style>
