<template>
  <order-summary-card
    v-bind="$attrs"
    v-model:freight-cost-code="revision.freight_cost_code"
    :attachments="invoice.attachments"
    :comments="invoice.comments"
    comments-title="Invoice comments"
    :contact="invoice.contact"
    :delivery-price="deliveryPrice"
    :freight-tax="revision.freight_tax"
    :items="items"
    :ordered-by="order.owner"
    :pickup-price="pickupPrice"
    :project="order.jobsite"
    :readonly="isApproved"
    :readonly-items="readonlyItems"
    :reference-name="order.reference_name"
    :summary-items="summaryItems"
    :supplier="invoice.vendor"
    :title="`Invoice ${invoice.invoice_number}`"
    :total="totalPrice"
    :total-tax="totalTax"
    @preview="$emit('preview', $event)"
  >
    <template v-slot:title-append>
      <invoice-status v-if="!order.date_cancelled" :invoice="invoice" />
    </template>
    <template v-slot:reference-name>
      <nuxt-link class="text-decoration-none" :to="{ name: 'orders-id', params: { id: order.id } }">
        {{ order.reference_name }}
      </nuxt-link>
    </template>
    <template v-if="!invoice.date_cancelled" v-slot:actions>
      <invoice-accounting-status v-if="isIntegrated" :invoice="invoice" />
      <slot name="actions-prepend" />
      <v-tooltip
        v-if="canApprove && !order.date_cancelled && !isApproved"
        class="text-center"
        :disabled="blockingErrors.length === 0"
        location="bottom"
        max-width="350"
      >
        <template v-slot:activator="{ props: activator }">
          <span v-bind="activator">
            <qtm-btn
              :disabled="blockingErrors.length > 0"
              :loading="loading"
              @click="approve"
            >
              Approve
            </qtm-btn>
          </span>
        </template>
        {{ blockingErrors[0]?.msg }}
      </v-tooltip>
      <v-menu v-if="canCancel" content-class="qtm-border">
        <template v-slot:activator="{ props: activator }">
          <qtm-icon-btn
            v-bind="activator"
            class="ml-1"
            color="primary"
            data-test="invoice-menu"
            icon="mdi-dots-horizontal"
            rounded="0"
            variant="outlined"
          />
        </template>

        <v-list class="py-0">
          <v-list-item class="py-0 px-6" :to="{ name: 'invoices-id-edit', params: { id: invoice.id } }">
            <v-list-item-title class="text-dark-grey">
              Edit
            </v-list-item-title>
          </v-list-item>
          <v-list-item class="py-0 px-6" @click="showCancelConfirmation = true">
            <v-list-item-title class="text-dark-grey" data-test="cancel-button">
              Cancel Invoice
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <template v-slot:summary-item-po>
      <span v-if="!poIssued" class="text-mid-grey">
        No PO Issued
      </span>
      <span v-else-if="po" class="cursor-pointer text-interactive" @click="$emit('po-click')">
        {{ po.po_number }}
      </span>
    </template>
    <template v-slot:supplier-append>
      <qtm-icon-btn
        v-if="canEmailSupplier"
        class="ml-3"
        icon="mdi-email-edit-outline"
        title="Email Supplier"
        @click="showSendSupplierEmail = true"
      />
    </template>
    <template v-slot:bottom-append>
      <div class="w-100">
        <comment-container
          class="mb-2"
          content-type="invoice"
          :content-id="Number(invoice.id)"
          :mentionable-users="projectUsers"
          @preview="$emit('preview', $event)"
        />
      </div>
    </template>
  </order-summary-card>
  <send-supplier-email v-model="showSendSupplierEmail" :invoice="invoice" :order="order" :po="po" />
  <confirmation-dialog
    v-model="showCancelConfirmation"
    cancel-button-text="Close"
    :loading="loading"
    ok-button-text="Cancel Invoice"
    title="Cancel Invoice"
    @confirm="cancel"
  >
    Are you sure you want to cancel this invoice?
  </confirmation-dialog>
</template>

<script setup lang="ts">
import type { Invoice, LineItem, Order, PurchaseOrder } from '@quotetome/materials-api'
import { dateYear } from '~/models/filters'
import CommentContainer from '@/components/comments/comment-container.vue'
import ConfirmationDialog from '@/components/confirmation-dialog.vue'
import InvoiceAccountingStatus from '@/components/invoices/invoice-accounting-status.vue'
import InvoiceStatus from '@/components/invoices/invoice-status.vue'
import OrderSummaryCard from '@/components/orders/order-summary-card.vue'
import SendSupplierEmail from '@/components/invoices/send-supplier-email.vue'

export interface Props {
  blockingErrors?: any[]
  canApprove?: boolean
  invoice: Invoice
  order: Order
  po?: PurchaseOrder
}

const props = withDefaults(defineProps<Props>(), {
  blockingErrors: () => [],
  po: undefined,
})
const emit = defineEmits(['approved', 'cancelled', 'po-click', 'preview'])

const items = ref<LineItem[]>([])
const loading = ref(false)
const projectUsers = ref([])
const showSendSupplierEmail = ref(false)
const showCancelConfirmation = ref(false)

const authStore = useAuthStore()
const isStaff = authStore.isStaff

const canCancel = computed(() => (props.canApprove || isStaff) && !props.invoice?.date_approved)
const canEmailSupplier = computed(() => !isApproved.value && props.invoice?.contact?.email)
const deliveryPrice = computed(() => revision.value?.delivery_charge)
const isApproved = computed(() => !!props.invoice?.date_approved)
const isIntegrated = computed(() => props.invoice.accounting_success || props.invoice.accounting_success === false)
const pickupPrice = computed(() => revision.value?.pickup_charge)
const poIssued = computed(() => !!props.po && !props.order.requiresAdjustments && !props.order.requiresInvoiceApproval)
const readItemsFromInvoice = computed(() => !!props.invoice.line_items?.length)
const readonlyItems = computed(() => {
  return poIssued.value ? items.value.filter(item => item.reference_identifier).map(item => item.id) : []
})
const revision = computed(() => {
  return readItemsFromInvoice.value ? props.invoice.revisions?.[0] : (props.po?.latest_change_order || {} as any)
})
const summaryItems = computed(() => {
  let dateItem = {
    label: 'Due Date',
    value: dateYear(props.invoice.date_due),
  }

  if (props.invoice.date_cancelled) {
    dateItem = {
      label: 'Date Cancelled',
      value: dateYear(props.invoice.date_cancelled),
    }
  }

  return [
    {
      label: 'Sent Date',
      value: dateYear(props.invoice.date_issued),
    },
    dateItem,
    {
      label: 'PO',
    }
  ]
})

const totalPrice = computed(() => (readItemsFromInvoice.value ? props.invoice.total_price : props.po?.total_price))
const totalTax = computed(() => (readItemsFromInvoice.value ? props.invoice.total_tax : props.po?.total_tax))

const { $api, $error, $toast } = useNuxtApp()

const approve = async () => {
  if (props.order.state.can_approve_invoice) {
    loading.value = true
    try {
      await $api.v1.invoices.patch(
        props.invoice.id as number,
        {
          revision: {
            freight_cost_code: revision.value.freight_cost_code,
            poskus: items.value
          }
        }
      )
      await $api.v1.invoices.approve(props.invoice.id as number)
      emit('approved', props.order)
      $toast.success('Invoice approved')
    }
    catch (error) {
      $error.report(error)
    }
    loading.value = false
  }
  else {
    if (items.value.some(item => !item.cost_code)) {
      $toast.error('Cost codes are required')
      return
    }

    loading.value = true
    try {
      await $api.v1.invoices.patch(props.invoice.id!, {
        revision: {
          freight_cost_code: revision.value.freight_cost_code,
          poskus: items.value
        }
      })

      if (props.po) {
        await $api.v1.purchaseOrders.update(
          props.po.id!,
          {
            latest_change_order: {
              freight_cost_code: revision.value.freight_cost_code,
              poskus: items.value.map(item => ({
                ...item,
                id: props.po?.poskus.find(i => i.internal_identifier === item.reference_identifier)?.id,
              }))
            }
          }
        )
      }

      const order = await $api.v1.rfqs.transition(props.order, 'invoice_approved')
      emit('approved', order)
      $toast.success('Invoice approved')
    }
    catch (error) {
      $error.report(error)
    }
    loading.value = false
  }
}

const cancel = async () => {
  loading.value = true
  try {
    await $api.v1.invoices.cancel(props.invoice.id!)
    emit('cancelled')
    $toast.success('Invoice cancelled')
  }
  catch (error) {
    $error.report(error)
  }
  loading.value = false
}

const fetchTeam = async () => {
  try {
    projectUsers.value = await $api.v1.jobsites.team(props.order.jobsite!.id!)
  }
  catch (error) {
    $error.report(error)
  }
}

onMounted(fetchTeam)

watchEffect(() => {
  if (readItemsFromInvoice.value) {
    items.value = props.invoice.line_items ?? []
  }
  else {
    items.value = props.po?.poskus ?? []
  }
})
</script>
