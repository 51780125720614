<template>
  <qtm-dialog-card
    v-bind="$attrs"
    closable
    title="Email Supplier"
    width="1200px"
    @update:model-value="$emit('update:model-value', $event)"
  >
    <v-row dense>
      <v-col cols="6">
        <qtm-input-label label="To" />
        <email-address-combobox density="compact" :model-value="to" readonly />
      </v-col>
      <v-col cols="6">
        <qtm-input-label label="CC" />
        <email-address-combobox density="compact" :model-value="userEmail" readonly />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <qtm-input-label label="Subject" />
        <qtm-text-field v-model="subject" density="compact" />
      </v-col>
    </v-row>
    <v-row dense>
      <v-col>
        <rich-text-editor v-model="body" autofocus />
      </v-col>
    </v-row>
    <v-row v-if="availableAttachments.length">
      <v-col>
        <qtm-input-label label="Attachments" />
        <qtm-select
          v-model="attachments"
          item-title="name"
          :items="availableAttachments"
          :menu-props="{ maxHeight: '400' }"
          multiple
          persistent-hint
          placeholder="Select Attachments"
        >
          <template v-slot:selection="{ item }">
            <v-chip class="qtm-body-small" variant="flat">
              <span>{{ item.raw.name }}&nbsp;</span>
              <a class="text-decoration-none" :href="item.raw.url" target="_blank" @click="open(item.raw.url)">
                <v-icon size="small">mdi-eye</v-icon>
              </a>
            </v-chip>
          </template>
        </qtm-select>
      </v-col>
    </v-row>
    <template v-slot:actions>
      <v-spacer />
      <qtm-btn :disabled="disabled" :loading="loading" size="x-large" @click="send">
        Send
      </qtm-btn>
    </template>
  </qtm-dialog-card>
</template>

<script setup lang="ts">
import type { Invoice, Order, PurchaseOrder } from '@quotetome/materials-api'
import EmailAddressCombobox from '@/components/emails/email-address-combobox.vue'
import RichTextEditor from '@/components/emails/rich-text-editor.vue'

export interface Props {
  invoice: Invoice
  order: Order
  po?: PurchaseOrder
}

const props = defineProps<Props>()
const emit = defineEmits(['update:model-value'])

const authStore = useAuthStore()
const user = authStore.user

const to = computed<string[]>(() => [props.invoice.contact?.email].filter(Boolean) as string[])
const userEmail = [user!.email as string]

const subject = ref('')
const body = ref('')

const attachments = ref<any[]>([])
const availableAttachments = ref([...props.invoice.attachments])
const PO_DOC_CATEGORY = 2
const poAttachments = props.po
  ?.attachments
  .po_attachments
  .filter(attachment => attachment.category === PO_DOC_CATEGORY)
  || []

poAttachments.sort((a, b) => a.date_uploaded - b.date_uploaded)

const poAttachment = poAttachments.pop()

if (poAttachment) {
  availableAttachments.value.push(poAttachment)
}

const setupData = () => {
  const validPo = props.po && !props.po.requiresAdjustments && !props.po.requiresInvoiceApproval

  attachments.value = availableAttachments.value
  body.value = ''
  subject.value = [
    `Invoice #${props.invoice.invoice_number}`,
    validPo ? `PO ${props.po.po_number}` : undefined,
    props.order.reference_name,
  ].filter(s => s).join(' | ')
}

setupData()

const disabled = computed(() => !subject.value || !body.value)
const open = (url: string) => window.open(url, '_blank')
const loading = ref(false)
const nuxtApp = useNuxtApp()
const send = async () => {
  loading.value = true
  const payload = {
    body: body.value,
    subject: subject.value,
    attachments: attachments.value.map(attachment => attachment.id),
  }
  try {
    await nuxtApp.$api.v1.invoices.emailSupplier(props.invoice.id!, payload)
    nuxtApp.$toast.success('Email sent')
    emit('update:model-value', false)
    setupData()
  }
  catch (error) {
    nuxtApp.$error.report(error)
  }
  loading.value = false
}
</script>
