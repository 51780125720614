<template>
  <div>
    <div class="d-flex justify-space-between mb-4">
      <tutorial-tooltip
        title="Stay Connected with Suppliers"
        value="seen_supplier_chat"
      >
        <div class="mt-4 qtm-h4 text-secondary">
          Supplier Chat
        </div>
        <template v-slot:text>
          Chat with suppliers for quick updates and faster decision-making.
          You'll be notified as soon as a supplier responds.
        </template>
      </tutorial-tooltip>
      <sort-menu-order
        v-if="messages.length"
        v-model="sortBy"
      />
    </div>
    <div v-if="!messages.length && !hideEmpty" class="bg-background pa-4">
      <div class="qtm-body text-mid-grey">
        Chat with your supplier about the quote.
      </div>
      <qtm-btn class="ml-n4" prepend-icon="mdi-message-outline" tertiary @click="hideEmpty = true">
        Message Your Supplier
      </qtm-btn>
    </div>
    <div
      v-else
      class="d-flex flex-column bg-background pa-4 messages-wrapper"
      :class="{ 'flex-column-reverse': sortBy === 'oldest' }"
    >
      <qtm-input-label class="py-5" color="dark-grey" :label="inputLabel">
        <qtm-textarea
          v-model.trim="message"
          v-model:focused="focused"
          auto-grow
          :autofocus="hideEmpty"
          hide-details
          placeholder="Type your message"
          :rows="expanded ? 3 : 1"
        />
        <div v-if="expanded" class="mt-2 text-right">
          <qtm-btn :loading="loading" tertiary @click="message = ''">
            Cancel
          </qtm-btn>
          <qtm-btn :disabled="!message" :loading="loading" @click="save">
            Send to Supplier
          </qtm-btn>
        </div>
      </qtm-input-label>
      <supplier-chat-message
        v-for="msg in sortedMessages"
        :key="msg.id"
        class="py-4"
        :mark-as-read="markAsRead"
        :message="msg"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Order, Quote } from '@quotetome/materials-api'
import SupplierChatMessage from '@/components/vendors/supplier-chat-message.vue'
import SortMenuOrder from '@/components/sort-menu-order.vue'
import TutorialTooltip from '@/components/tutorial-tooltip.vue'

export interface Props {
  order: Order
  quote: Quote
}

const props = defineProps<Props>()

const authStore = useAuthStore()
const user = authStore.user!
const userName = `${user.first_name} ${user.last_name}`

const focused = ref(false)
const loading = ref(false)
const message = ref('')
const messages = ref<any[]>([])
const hideEmpty = ref(false)
const sortBy = ref('newest')

const contact = computed(() => props.quote.salesperson)
const expanded = computed(() => focused.value || message.value)
const inputLabel = computed(() => {
  const base = userName

  if (contact.value && expanded.value) {
    return `${base} to ${contact.value.first_name} ${contact.value.last_name}`
  }

  return base
})
const markAsRead = computed(() => user.id === props.order.owner!.id)
const sortedMessages = computed(() => {
  const toSort = messages.value

  toSort.sort((a, b) => b.date_created - a.date_created)

  return toSort
})

const { $api, $error, $toast } = useNuxtApp()

watch(() => props.quote, async () => {
  messages.value = []

  loading.value = true
  try {
    messages.value = await $api.v1.quotes.chat.listAll(props.quote.id!)
  }
  catch (error) {
    $error.report(error)
  }
  loading.value = false
}, { immediate: true })

const save = async () => {
  loading.value = true
  try {
    const newMessage = await $api.v1.quotes.chat.create(props.quote.id!, { text: message.value })

    messages.value.push(newMessage)
    $toast.success('Message sent')
    message.value = ''
  }
  catch (error) {
    $error.report(error)
  }
  loading.value = false
}
</script>

<style lang="scss" scoped>
$border: 1px solid rgb(var(--v-theme-light-grey));

.messages-wrapper>*:not(:last-child) {
  border-bottom: $border;
}

.messages-wrapper.flex-column-reverse>*:not(:last-child) {
  border-top: $border;
  border-bottom: none;
}
</style>
